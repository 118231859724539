.navbar {
    font-size: 20px;
    z-index: 2;
    transition: all .5s;
    background: transparent;
    color: var(--text-primary);
}

.navbar-nav button {
    border: transparent;
    width: 50px;
    font-size: 20px;
}

.navbar-toggler {
    border: none;
}
