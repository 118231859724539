:root {
    --background: white;
    --text-primary: rgb(29, 29, 29);
}

[data-theme="dark"] {
    --background: rgb(29, 29, 29);
    --text-primary: white;
}

/* Classes */
.App {
    background: var(--background);
    color: var(--text-primary);
    transition: all .5s;
}
